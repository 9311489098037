.appname {
    display: flex;
    flex-direction: row;

    .logo {
        height: $header_height;
        aspect-ratio: 1 / 1;
        text-align: center;
        margin-left: 5px;
        img {
            height: calc($header_height - 30px);
            @include centerelement($header_height, calc($header_height - 30px - $header_padding-top));
        }
    }

    .name {
        height: $header_height;
        padding-top: $header_padding-top;

        h1 {
            font-family: $font-primary;
            margin: 0;
            font-size: 28px;
            letter-spacing: 0px;
            font-weight: 700;
            line-height: $header_height - $header_padding-top;
            color: $white;
        }
    }
}